import { Redirect, Router } from '@reach/router'
import * as React from 'react'
import Routes from './routes'
import Timeout from './components/UserSession/Timeout'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'

const Login = React.lazy(() => import('./pages/Login'))
const MainLayoutRoute = React.lazy(() => import('./routes/MainLayoutRoute'))
const VersionRoute = React.lazy(() => import('./routes/VersionRoute'))

export const App: React.FC = () => (
  <React.Suspense fallback={null}>
    <Timeout />
    <AuthenticatedTemplate>
      <Router>
        <MainLayoutRoute path={`${Routes.TICKET}/*`} />
        <VersionRoute path={`${Routes.VERSION}`} />
      </Router>
      <Redirect
        from="/"
        to={
          window.location.pathname.includes('ticket') || window.location.pathname.includes('version')
            ? window.location.pathname
            : '/ticket'
        }
        noThrow
      />
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Router>
        <Login path={Routes.LOGIN} />
      </Router>
      <Redirect from="/" to={Routes.LOGIN} noThrow />
    </UnauthenticatedTemplate>
  </React.Suspense>
)

export default App
