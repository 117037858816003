import * as React from 'react'
//  @ts-ignore
import { getCountries } from 'country-fns'
import { alpha2ToAlpha3 } from 'i18n-iso-countries'
import { Country } from 'types/global'
import Input, { InputProps } from 'components/Input'

const countries: Array<Country> = getCountries()

export const alpha3Options = countries.map(({ name, iso2 }) => ({
  label: name,
  value: alpha2ToAlpha3(iso2.toUpperCase()),
}))

export const CountrySelect: React.FC<InputProps> = (props) => (
  <Input.Select {...props} placeholder="--" isClearable options={alpha3Options} />
)

export default CountrySelect
