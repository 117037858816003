// @ts-ignore
import { getCountry } from 'country-fns'
import * as React from 'react'
import { FieldRenderProps } from 'react-final-form'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import Input from './Input'

const defaultCountry = getCountry('us')

export type Props = NumberFormatProps &
  FieldRenderProps<HTMLInputElement> &
  Omit<React.ComponentProps<typeof Input>, 'ref'> & { mask?: string; dial?: string }

export const PhoneNumber: React.FC<Props> = ({ input, mask = defaultCountry.format, dial = defaultCountry.dial, ...rest }) => {
  const type = input && input.type ? (input.type as NumberFormatProps['type']) : rest.type
  const phoneNumberStripped = `${input.value}`.match(/[0-9]+/g)?.join('') || `${input.value}` // Cast as string
  const phoneNumberLength = phoneNumberStripped.length
  // Number of expected digits in format
  const numberOfFormatDigits = mask.split('.').length - 1
  const isNumberPreformatted = phoneNumberLength + dial.length > numberOfFormatDigits
  const dialInFormatCharacters = dial.replaceAll(/[\d+]/g, '.')
  const customMaskWithAdditionalCode = dialInFormatCharacters + mask.slice(dial.length + 1) + mask.slice(`${input.value}`.length)

  return (
    <NumberFormat
      {...(input as any)}
      {...rest}
      value={isNumberPreformatted ? phoneNumberStripped.slice(dial.length) : `${input.value}`}
      type={type}
      format={formatMask(isNumberPreformatted ? customMaskWithAdditionalCode : mask, dial, '#')}
      placeholder={formatMask(isNumberPreformatted ? customMaskWithAdditionalCode : mask, dial, '_')}
      mask={'_'}
      customInput={Input}
    />
  )
}

const nthOccurence = (string: string, subString: string, index: number) => string.split(subString, index).join(subString).length

// modify input mask to remove dial code
const formatMask = (mask?: string, dial?: string, newSubstr?: string) => {
  if (!mask || !dial) return undefined

  const startingIndex = nthOccurence(mask, '.', dial.length + 1)
  let formattedMask

  // account for masks where our starting index is preceeded with a (
  if (mask[startingIndex - 1] === '(') {
    formattedMask = mask.slice(startingIndex - 1)
  } else {
    formattedMask = mask.slice(startingIndex)
  }

  return newSubstr ? formattedMask.replace(/\./g, newSubstr) : formattedMask
}

export default PhoneNumber
