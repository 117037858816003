import React, { createContext, useContext } from 'react'
import { useDialogState, DialogInitialState } from 'reakit/Dialog'

export const createDialogContext = (initialState?: DialogInitialState) => {
  const dialogContext = createContext(initialState as ReturnType<typeof useDialogState>)

  const Provider = ({ children }) => {
    const dialog = useDialogState(initialState)

    return <dialogContext.Provider value={dialog}>{children}</dialogContext.Provider>
  }

  const useDialogContext = () => useContext(dialogContext)

  return { Provider, useDialogContext }
}

export default createDialogContext
