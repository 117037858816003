import { useServiceProxy } from 'hooks/kong'
import { DynamoContacts } from 'types/contacts'

export function useContactsRefresh() {
  const serviceProxy = useServiceProxy()

  return async (eventId: string) => {
    return await serviceProxy<{ Items: DynamoContacts[] }>('get', `/serviceproxy/contacts-table/items?eventIdentifier=${eventId}`)
  }
}

export default useContactsRefresh
