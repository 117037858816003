import * as React from 'react'
import styled from 'styled-components/macro'
import BodyScroll from './BodyScroll'
import { Portal } from 'reakit/Portal'
import { DialogStateReturn, Dialog as ReakitDialog, DialogBackdrop } from 'reakit/Dialog'

const StyledDialog = styled(ReakitDialog as any)`
  position: fixed;
  z-index: 19900410;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  padding: 1em;
  border-radius: 0.25em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
`
const StyledTimeoutDialog = styled(ReakitDialog as any)`
  position: fixed;
  width: 50%;
  z-index: 19900410;
  top: 41%;
  left: 25%;
  border-radius: 0.25em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
`

const StyledBackdrop = styled(DialogBackdrop as any)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 199004;
`

function Dialog({
  children,
  hideOnEsc,
  timeout,
  ...dialog
}: DialogStateReturn & {
  children: React.ReactNode
  'aria-label': string
  hideOnEsc?: boolean
  timeout?: boolean
}) {
  let styledComponent = (
    <StyledDialog hideOnClickOutside={false} hideOnEsc={hideOnEsc} {...dialog}>
      <BodyScroll scroll={!dialog.visible} />
      {children}
    </StyledDialog>
  )

  if (timeout) {
    styledComponent = (
      <StyledTimeoutDialog hideOnClickOutside={false} hideOnEsc={hideOnEsc} {...dialog}>
        <BodyScroll scroll={!dialog.visible} />
        {children}
      </StyledTimeoutDialog>
    )
  }
  return (
    <>
      <Portal>
        <StyledBackdrop {...dialog} />
      </Portal>
      {styledComponent}
    </>
  )
}

export default Dialog
