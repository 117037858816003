// @ts-ignore
import { getCountry } from 'country-fns'
import * as React from 'react'
import { Field as FinalFormField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Flex, Field } from 'components/primitives'
import Margin from 'components/Margin'
import { PhoneNumber } from './FinalFormInputs'
import CountryCodeSelect from 'components/Select/CountryCodeSelect'
import { Maybe, SelectOption } from 'types/global'

type Props = {
  label: string
  smallLabel?: string
  countryCodeName?: string
  numberName?: string
  defaultPhoneNumber?: string
  defaultPhoneCountryCode?: Maybe<SelectOption<string>>
}

export function Phone({
  countryCodeName = 'countryCode',
  numberName = 'number',
  label,
  smallLabel,
  defaultPhoneNumber,
  defaultPhoneCountryCode,
}: Props) {
  const { t } = useTranslation()

  return (
    <Margin use={Flex} spacing="md" style={{ flex: 1 }}>
      <Field style={{ flex: 4 }}>
        <CountryCodeSelect
          label={t('Country')}
          name={countryCodeName}
          spacing="none"
          defaultValue={defaultPhoneCountryCode as any}
        />
      </Field>
      <FinalFormField name={countryCodeName} subscription={{ value: true }}>
        {({ input: { value: option } }) => {
          const { format, dial } = option ? getCountry(option.value) : getCountry('us')

          return (
            <Field style={{ flex: 5 }}>
              <div style={{ fontSize: '0.72rem' }}>{smallLabel}</div>
              <PhoneNumber
                label={label}
                name={numberName}
                mask={format}
                dial={dial}
                spacing="none"
                initialValue={defaultPhoneNumber as any}
              />
            </Field>
          )
        }}
      </FinalFormField>
    </Margin>
  )
}

export default Phone
