// @ts-ignore
import { getCountry } from 'country-fns'
import { isNil } from 'lodash'
import { Maybe, SelectOption } from 'types/global'

export const applyMask = (pattern: string, value: string) => {
  let i = 0
  const v = value.toString()
  return `${pattern.replace(/\./g, (_) => v[i++] || '')}`
}

export const formatPhone = (number: Maybe<string>, countryCode?: SelectOption<any>) => {
  if (isNil(number) || isNil(countryCode) || !countryCode.value) return null

  try {
    const country = getCountry(countryCode.value)
    if (!country) return number
    const { format, dial } = country

    const strippedNumberFormatting = number.match(/[0-9]+/g)?.join('') || number
    const customFormattingLength = dial.length + strippedNumberFormatting.length
    const numberOfFormatDigits = format.split('.').length - 1
    const isNumberPreformatted = customFormattingLength > numberOfFormatDigits
    const dialInFormatCharacters = dial.replaceAll(/[\d+]/g, '.')
    const customMaskWithAdditionalCode =
      '+' + dialInFormatCharacters + format.slice(dial.length + 1) + format.slice(number.length - 1)

    const pattern = isNumberPreformatted ? customMaskWithAdditionalCode : format
    const value = isNumberPreformatted ? strippedNumberFormatting : `${dial}${strippedNumberFormatting}`

    return applyMask(pattern, value)
  } catch (e) {
    console.error(e)
    return number
  }
}

export const parsePhone = (number: Maybe<string>, countryCode: SelectOption<any>) => {
  if (isNil(number) || isNil(countryCode) || !countryCode.value) return null
  return {
    number: number.replace(/\D/g, ''), // remove all formatting
    countryCode: countryCode.value,
  }
}
