import { alpha3ToAlpha2 } from 'i18n-iso-countries'
import { alpha3Options } from 'components/Select/CountrySelect'
import { findCountryOption } from 'components/Select/CountryCodeSelect'
import { Driver, TransformedDriver, MultiRetrieveResponse, TransformedTicket, Vehicle, GetCountries } from 'types/ticket'
import { Branch } from 'types/global'
import { isNil, pick } from 'lodash-es'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { getCountry } from 'country-fns'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'

function sanitizePrimaryDriver(primaryDriver: Driver) {
  if (Object.values(primaryDriver).every((prop) => !prop)) return null
  return primaryDriver
}

export const composeSearchTerms = (terms: object) =>
  Object.entries(terms)
    .map(([key, value]) => `${key}:${value}`)
    .join(' ')

const transformVehicle = (vehicle: Vehicle) => {
  if (!vehicle) return null

  return {
    ...vehicle,
    vinLast8: vehicle.vin && vehicle.vin.slice(-8),
    licensePlate: vehicle.licensePlate ? vehicle.licensePlate.number : null,
    licensePlateState: vehicle.licensePlate ? vehicle.licensePlate.countrySubdivision : null,
  }
}

const transformDriver = (driver: Driver | null): TransformedDriver | null => {
  if (!driver) return null
  try {
    const address = driver.address || []
    const phone = (driver as Driver).phone || []
    return {
      ...driver,
      fullName: [driver.firstName, driver.lastName].filter(Boolean).join(' '),
      address: address.map((addr) => ({
        ...addr,
        countryOption: alpha3Options.find((c) => c.value === addr.countryCode),
      })),
      phone: phone.map((p) => ({
        ...p,
        countryOption: alpha3ToAlpha2(p.countryCode)
          ? findCountryOption(alpha3ToAlpha2(p.countryCode)!)
          : { label: 'United States +1', value: 'us' },
      })),
    }
  } catch (e) {
    console.error('Error transforming driver')
    console.error(e)
  }
  return null
}

function formatPhoneNumber(primaryDriver: Driver | null): Driver | null {
  if (!primaryDriver) return null
  const { number } = primaryDriver.phone[0]

  // countryCode can be null. default to USA
  const countryCode = primaryDriver.phone[0]?.countryCode || 'USA'
  // Alpha3ToAlpha2 codes: https://github.com/michaelwittig/node-i18n-iso-countries/blob/master/codes.json
  const alpha3 = alpha3ToAlpha2(countryCode) || 'us' // Default to us again
  // getCountry objects from alpha2: https://github.com/jaredpalmer/country-fns/blob/master/index.js
  const countryInfo = getCountry(alpha3) as GetCountries

  const expectedPhoneLength = (countryInfo.format.match(/\./g) || []).length

  // Check if the number of digits of incoming phone number matches expected format length
  const isCorrectFormatLength = expectedPhoneLength === number.length

  // Check for "+" in phone string
  const isPhoneMissingPlusSign = number.includes('+')
  const isPhoneFormatted = isCorrectFormatLength && !isPhoneMissingPlusSign

  let phoneNumberToParse = ''

  switch (true) {
    case isPhoneFormatted: {
      // We don't need to make any changes
      phoneNumberToParse = number
      break
    }
    case isCorrectFormatLength && isPhoneMissingPlusSign: {
      // Correct length but missing "+"
      phoneNumberToParse = `+${number}`
      break
    }
    // Otherwise prefix "+" & dial code to phone number
    default:
      phoneNumberToParse = `+${countryInfo.dial} ${number}`
  }

  const phoneNumber = parsePhoneNumberWithError(phoneNumberToParse)

  primaryDriver.phone[0].number = phoneNumber.formatNational()
  return primaryDriver
}

export function transformMultiRetrieveTicket(
  ticket: MultiRetrieveResponse,
  solr: { branches: Branch[] },
  ldConfig: LDFlagSet,
): TransformedTicket {
  const { roadsideIsNullDriver } = ldConfig
  const isNullDriver = isNil(ticket.driver.primaryDriver) && roadsideIsNullDriver
  const primaryDriver = formatPhoneNumber(sanitizePrimaryDriver(isNullDriver ? mockDriver : ticket.driver.primaryDriver))

  let additionalDriver = ticket.driver.additionalDriver.filter((driver) => !Object.values(driver).every((v) => v === null))
  const returnBranch = solr.branches[1] ? solr.branches[1] : solr.branches[0]
  return {
    ...ticket,
    primaryDriver: isNullDriver ? transformDriver(mockDriver) : transformDriver(primaryDriver),
    additionalDriver,
    drivers: [primaryDriver, ...additionalDriver].map(transformDriver).filter(Boolean),
    vehicle: transformVehicle(ticket.vehicle.currentVehicle),
    vehicleHistory: Array.isArray(ticket.vehicle.vehicleHistory) ? ticket.vehicle.vehicleHistory.map(transformVehicle) : [],
    pickupInfo: {
      ...pick(ticket.pickupInfo, ['dateTime', 'method']),
      groupBranchId: ticket.pickupInfo.location.groupBranchId,
      branch: {
        ...solr.branches[0],
      },
    },
    returnInfo: {
      ...pick(ticket.returnInfo, ['dateTime', 'method']),
      groupBranchId: ticket.returnInfo.location.groupBranchId,
      branch: {
        ...returnBranch,
      },
    },
  }
}

const mockDriver: Driver = {
  individualId: '12345',
  address: [
    {
      city: 'St. Louis',
      countryCode: 'USA',
      countryDescription: '',
      countrySubdivision: '',
      country: 'USA',
      line1: '211 North Broadway',
      line2: '',
      line3: '',
      postalCode: '63102',
      type: 1,
    },
  ],
  dateOfBirth: '07/01/2024',
  dayOfBirth: '01',
  monthOfBirth: '07',
  firstName: 'Driver Is Null',
  lastName: 'From API',
  salutation: '',
  insuranceInfo: {
    carrierName: '',
    policyIdentifier: '',
  },
  legalId: [],
  phone: [
    {
      countryCode: 'USA',
      number: '1234567890',
      type: 1,
    },
  ],
  suffix: '',
  emailAddress: '',
}
