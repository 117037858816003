//  @ts-ignore
import { getCountries } from 'country-fns'
import * as React from 'react'
import { lowerCase } from 'lodash-es'
import { SelectOption } from 'types/global'
import { InputProps } from 'components/Input'
import { Select } from 'components/Input/FinalFormInputs'

type Country = {
  iso2: string
  dial: string
  name: string
}

export const countryOptions = (getCountries() as Country[]).map((country) => ({
  label: `${country.name.replace(/ *\([^)]*\) *|\)|\(/g, '')} +${country.dial}`,
  value: country.iso2,
}))

export const findCountryOption = (value: string) =>
  countryOptions.find((o) => o.value === lowerCase(value)) || defaultCountryOption

export const defaultCountryOption = countryOptions.find((o) => o.value === 'us') as SelectOption<any>

const CountryCodeSelect: React.FC<InputProps> = (props) => (
  <Select {...props} options={countryOptions} defaultValue={defaultCountryOption as any} isSearchable={true} />
)

export default CountryCodeSelect
