import { alpha3ToAlpha2 } from 'i18n-iso-countries'
import { TransformedDriver } from 'types/ticket'
import { CallerRelationship, Callers } from 'types/callstore'
import { Caller, Maybe, SelectOption } from 'types/global'
import { findCountryOption, defaultCountryOption } from 'components/Select/CountryCodeSelect'
import { useSelector } from 'react-redux'
import { selectLocalQuickNotes, selectFormsState } from 'redux/appStore'
import { isEmpty, isNil } from 'lodash-es'
import { useTranslation } from 'react-i18next'

export const getCountryOption = (countryCode?: string): SelectOption => {
  if (countryCode) return findCountryOption(alpha3ToAlpha2(countryCode) || 'USA') || defaultCountryOption!
  return defaultCountryOption!
}

const formatDriverPayload = (
  driver: TransformedDriver | null,
  quickPhone: Maybe<string>,
  quickCountryCode: SelectOption<any>,
) => {
  if (isNil(driver)) return null

  const shouldUseQuickNotes = Boolean(quickPhone) && Boolean(quickCountryCode)
  const callerName = driver.fullName
  const number = shouldUseQuickNotes ? quickPhone : driver.phone?.[0]?.number || ''
  const countryOption = driver.phone?.[0]?.countryOption ?? null
  const countryCode = shouldUseQuickNotes
    ? quickCountryCode
    : getCountryOption(driver.phone?.[0]?.countryCode) || defaultCountryOption

  return {
    callerName,
    phone: {
      number,
      countryOption,
      countryCode,
    },
  }
}

export function useCallerFormValues(drivers: TransformedDriver[] | null) {
  const { t } = useTranslation()
  const quickNotes = useSelector(selectLocalQuickNotes)
  const { countryCode: quickCountryCode, number: quickPhone } = quickNotes
  const forms = useSelector(selectFormsState)
  const localFormState = !isNil(localStorage.getItem('localFormStore'))
    ? (JSON.parse(localStorage.getItem('localFormStore') as string).caller as Callers)
    : null

  const reduxForm = forms.caller as unknown as Callers
  const reduxFormState = forms.caller && forms.caller?.getState?.().values

  const callerState = (reduxFormState || reduxForm) as Callers
  const {
    callerName,
    selectedCaller,
    callerRelationship,
    drivers: initialDrivers,
    confirmedDOB,
    companyConfirmation = false,
    optIn,
    countryCode,
    number,
  } = !isNil(localFormState) ? localFormState : callerState
  const shouldUseQuickNotes = Boolean(quickPhone) && Boolean(quickCountryCode)
  const isDriverSelected = selectedCaller && selectedCaller.includes('caller-')
  const driverIndex = isDriverSelected ? parseInt(selectedCaller.split('caller-')[1]) : 0
  const selectedDriver = drivers![driverIndex]
  const selectedDriversPhoneNumber = selectedDriver.phone[0].number

  const selectedDriversCountryCode = selectedDriver.phone[0].countryOption
    ? findCountryOption(selectedDriver.phone[0].countryOption.value)
    : getCountryOption(selectedDriver[0].countryCode)

  const initialNumber = shouldUseQuickNotes ? quickPhone : isDriverSelected ? selectedDriversPhoneNumber : number || ''

  const initialCountryCode = shouldUseQuickNotes
    ? quickCountryCode
    : isDriverSelected
      ? selectedDriversCountryCode
      : countryCode || defaultCountryOption

  const initialRelationship =
    selectedCaller && selectedCaller.includes('account') && (callerRelationship.value as string) === ''
      ? {
          label: t(`callerRelationship.coworker`),
          value: CallerRelationship.COWORKER,
        }
      : callerRelationship

  const initialDriver = {
    callerName,
    number: initialNumber,
    countryCode: initialCountryCode,
    selectedCaller,
    callerRelationship: initialRelationship,
    drivers: !isEmpty(initialDrivers)
      ? initialDrivers
      : !isNil(drivers)
        ? (drivers.map((driver) => formatDriverPayload(driver, quickPhone, quickCountryCode)).filter(Boolean) as Caller[])
        : [],
    confirmedDOB,
    companyConfirmation,
    optIn,
  }

  return initialDriver
}

export default useCallerFormValues
