import { EventTypes, DynamoEvents } from 'types/events'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxState, selectCurrentEvent, selectTicket, selectAllEvents } from 'redux/appStore'
import { useServiceProxy } from 'hooks/kong'
import { useCallback, useEffect, useRef } from 'react'
import { updateTicketHistoryEvents } from 'redux/callHistory/callHistorySlice'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'
import { config } from '../../../config'
import useEventPolling from '../useEventPolling'
import { useFlags } from 'launchdarkly-react-client-sdk'

// TODO: RDS-1370 Call event table for most recent dispatches
export function useCallEvents() {
  const dispatch = useDispatch()
  const serviceProxy = useCallback(useServiceProxy(), [])
  const currentTicket = useSelector(selectTicket)
  const currentEvents = useSelector(selectAllEvents)
  const currentEventsRef = useRef(currentEvents)
  const ticketNumber = currentTicket?.ticketNumber
  const { roadsideIsAaaHookActive } = useFlags()

  useEffect(() => {
    currentEventsRef.current = currentEvents
  }, [currentEvents])

  const updateReduxEvents = async () => {
    const { data } = await serviceProxy<{ Items: DynamoEvents[] }>(
      'get',
      `/serviceproxy/events-table/items?rentalContext=${config.rentalAgreementUrn}${ticketNumber}`,
    )
    dispatch(updateTicketHistoryEvents({ historyEvents: [...data.Items] }))
    Object.keys(currentEventsRef.current).forEach((key) => {
      const event = currentEventsRef.current[key]
      if (event?.event) {
        if (event.event.provider === 'aaa' && roadsideIsAaaHookActive) {
          const updateEvent = data.Items.find(
            (item) => item.eventIdentifier === event.event.eventIdentifier && item.provider === 'aaa',
          )
          if (updateEvent) {
            dispatch(setCurrentEvent({ eventType: event.event.eventType, newEvent: updateEvent }))
          }
        }
      }
    })
  }

  useEventPolling(updateReduxEvents, [ticketNumber, roadsideIsAaaHookActive])
}

export function useCallEvent(eventType?: EventTypes) {
  const events = useSelector((state: ReduxState) => selectCurrentEvent(state)(eventType))
  return events
}

export default useCallEvents
