import { useMsal } from '@azure/msal-react'
import { Method } from 'axios'
import { useCallback } from 'react'
import { navigate } from '@reach/router'
import { msalConfig } from 'components/Auth/getAzureConfig'
import { ServiceProxyCall } from 'types/global'
import { globalConfig, useAxios } from '../useAxios'
import { config } from 'config'
import { useFlags } from 'launchdarkly-react-client-sdk'

const validMethods = [
  'get',
  'GET',
  'delete',
  'DELETE',
  'head',
  'HEAD',
  'options',
  'OPTIONS',
  'post',
  'POST',
  'put',
  'PUT',
  'patch',
  'PATCH',
  'purge',
  'PURGE',
  'link',
  'LINK',
  'unlink',
  'UNLINK',
]
const isValidRestMethod = (method: any): method is Method => validMethods.includes(method)

export function useServiceProxy(): ServiceProxyCall {
  const axios = useAxios()
  const { instance, accounts } = useMsal()
  const { roadsideIsEast2ServicesEnabled } = useFlags()
  const { serviceProxyUrl } = config

  const serviceProxyCall = useCallback(
    async <ResponseType>(method: any, endpoint: string, headers?: any, body?: any, params?: any) => {
      const serverTelemetryKey = localStorage.getItem(`server-telemetry-${msalConfig.clientId}`) // Grabs key from localStorage
      const telemetryData = serverTelemetryKey ? JSON.parse(serverTelemetryKey) : null

      if (telemetryData?.errors && telemetryData?.errors.length > 0) {
        // Check for specific errors
        const isBadTokenError = telemetryData.errors.includes('bad_token')
        const isNoTokenError = telemetryData.errors.includes('no_tokens_found')
        const isRefreshTokenExpired = telemetryData.errors.includes('refresh_token_expired')

        // Force logout if errors exist
        if (isBadTokenError || isNoTokenError || isRefreshTokenExpired) {
          instance.logoutRedirect({
            // Silent logout to prevent navigate to microsoft logout page
            onRedirectNavigate() {
              navigate('/login')
              localStorage.removeItem('state') // Clear the Redux Cache in localstorage
              return false
            },
          })
        }
      }

      if (!isValidRestMethod(method)) {
        throw new Error('Invalid REST Method')
      }

      const { accessToken } = await instance.acquireTokenSilent({
        scopes: msalConfig.scopes,
        account: accounts[0],
      })

      const acceptHeader =
        window.location.hostname === 'staging.hero.ehi.com' || roadsideIsEast2ServicesEnabled
          ? 'application/json; version=1.0.0-green' // Default to east-2 services on staging
          : 'application/json; version=1.0.0'

      const config = {
        headers: {
          Accept: acceptHeader,
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          ...headers,
        },
        ...globalConfig,
        params,
      }

      if (window.location.hostname !== 'localhost') {
        endpoint = serviceProxyUrl + endpoint.replace('serviceproxy', 'proxy')
      }
      let apiCall
      switch (method.toLowerCase()) {
        case 'get':
          apiCall = axios.get<ResponseType>(endpoint, config)
          break
        case 'post':
          apiCall = axios.post<ResponseType>(endpoint, body, config)
          break
        case 'put':
          apiCall = axios.put<ResponseType>(endpoint, body, config)
          break
        case 'delete':
          apiCall = axios.delete<ResponseType>(endpoint, { ...config, data: body })
          break
      }
      try {
        return await apiCall
      } catch (error) {
        console.error(
          `Error in serviceProxyCall Method: ${method}, endpoint: ${endpoint}, headers: ${headers}, body: ${body}`,
          error,
        )
      }
    },
    [axios, instance, accounts, serviceProxyUrl],
  )

  return serviceProxyCall
}
